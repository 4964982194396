/* eslint-disable */
import { FieldArray, FormikProvider, getIn } from 'formik';
import moment from 'moment';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';

// MUI
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Typography from '@mui/material/Typography';
import {
  Box,
  Grid,
  IconButton,
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import DragHandleIcon from '@mui/icons-material/DragHandle';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

// MDPR
import MDButton from 'mdpr2/components/MDButton';

// WRM
// eslint-disable-next-line import/no-cycle
import FormField from './index';

const FieldCollection = (props) => {
  const {
    addButtonLabel,
    childFields,
    draggable,
    formik,
    getApiError,
    getHeading,
    label,
    name,
    showFileBrowser,
  } = props;

  // eslint-disable-next-line no-shadow
  const handleDragEnd = (result) => {
    if (!result.destination) {
      return;
    }
    if (result.destination.index === result.source.index) {
      return;
    }
    const values = getIn(formik.values, name);
    const reorderedValues = [];
    const movedValue = values[result.source.index];
    values.forEach((value, index) => {
      if (index === result.destination.index && result.destination.index < result.source.index) {
        reorderedValues.push(movedValue);
      }
      if (index !== result.source.index) {
        reorderedValues.push(value);
      }
      if (index === result.destination.index && result.destination.index >= result.source.index) {
        reorderedValues.push(movedValue);
      }
    });
    formik.setFieldValue(name, reorderedValues);
  };

  let values = getIn(formik.values, name);
  if (!Array.isArray(values)) {
    values = [];
  }

  // https://formik.org/docs/api/useFormik
  // Be aware that <Field>, <FastField>, <ErrorMessage>, connect(), and <FieldArray> will NOT work with useFormik() as they all require React Context.
  // Solution: Wrap <FieldArray> in <FormikProvider>
  return (
    <FormikProvider value={formik}>
      <FieldArray
        name={name}
        render={(fieldArrayHelpers) => (
          <>
            <Grid
              container
              justifyContent="space-between"
              paddingBottom={2}
            >
              <Grid item>
                <Typography
                  sx={{ mt: 1 }}
                  variant="h6"
                >
                  {label}
                </Typography>
              </Grid>
              <Grid item>
                <MDButton
                  color="info"
                  onClick={() => {
                    const prototype = {};
                    // eslint-disable-next-line no-shadow
                    childFields.forEach(({ name, type }) => {
                      if (type === 'fieldCollection') {
                        prototype[name] = [];
                      } else {
                        prototype[name] = '';
                      }
                    });
                    prototype.uuid = `${Math.random().toString(36).replace(/[^a-z]+/g, '').substr(0, 5)}-${moment().valueOf()}`;
                    fieldArrayHelpers.push(prototype);
                  }}
                  startIcon={<AddIcon fontSize="small" />}
                >
                  { addButtonLabel || 'Add' }
                </MDButton>
              </Grid>
            </Grid>
            <DragDropContext onDragEnd={handleDragEnd}>
              <Droppable
                direction="vertical"
                droppableId="accordion-droppable"
              >
                {
                  (droppableProvided) => (
                    <Box
                      sx={{ ml: 1 }}
                      ref={droppableProvided.innerRef}
                      /* eslint-disable-next-line react/jsx-props-no-spreading */
                      {...droppableProvided.droppableProps}
                    >
                      {
                        values.map((childValue, childIndex) => (
                          <Draggable
                            draggableId={`accordion-${childValue['@id'] || childValue.uuid}`}
                            index={childIndex}
                            key={`accordion-${childValue['@id'] || childValue.uuid}`}
                          >
                            {
                              (draggableProvided, snapshot) => (
                                <Accordion
                                  key={`accordion-${name}-${childValue['@id'] || childValue.uuid}`}
                                  TransitionProps={{ unmountOnExit: true }}
                                  ref={draggableProvided.innerRef}
                                  /* eslint-disable-next-line react/jsx-props-no-spreading */
                                  {...draggableProvided.draggableProps}
                                  style={{
                                    ...draggableProvided.draggableProps.style,
                                    background: snapshot.isDragging && 'rgba(0, 0, 0, 0.10)',
                                  }}
                                >
                                  <AccordionSummary
                                    expandIcon={<ExpandMoreIcon />}
                                    style={{
                                      background: snapshot.isDragging ? 'none' : 'rgba(0, 0, 0, .025)',
                                    }}
                                  >
                                    <Grid
                                      container
                                      justifyContent="space-between"
                                    >
                                      <Grid item>
                                        <Grid
                                          container
                                          spacing={2}
                                        >
                                          <Grid item hidden={!draggable}>
                                            {/* eslint-disable-next-line react/jsx-props-no-spreading */}
                                            <div {...draggableProvided.dragHandleProps}><DragHandleIcon /></div>
                                          </Grid>
                                          <Grid item>
                                            <Typography variant="subtitle2">{getHeading ? getHeading(childValue, childIndex) : `#${childIndex + 1}`}</Typography>
                                          </Grid>
                                        </Grid>
                                      </Grid>
                                      <Grid item>
                                        <IconButton
                                          color="error"
                                          onClick={() => fieldArrayHelpers.remove(childIndex)}
                                          size="small"
                                        >
                                          <DeleteIcon fontSize="small" />
                                        </IconButton>
                                      </Grid>
                                    </Grid>
                                  </AccordionSummary>
                                  <AccordionDetails>
                                    <Grid
                                      container
                                      mt={0}
                                      spacing={2}
                                    >
                                      {
                                        childFields.map((childField) => {
                                          const field = { ...childField };
                                          field.name = `${name}[${childIndex}].${childField.name}`;
                                          return (
                                            <Grid
                                              item
                                              key={`grid-${field.name}`}
                                              md={12}
                                              xs={12}
                                            >
                                              <FormField
                                                /* eslint-disable-next-line react/jsx-props-no-spreading */
                                                {...field}
                                                formik={formik}
                                                getApiError={getApiError}
                                                showFileBrowser={showFileBrowser}
                                              />
                                            </Grid>
                                          );
                                        })
                                      }
                                    </Grid>
                                  </AccordionDetails>
                                </Accordion>
                              )
                            }
                          </Draggable>
                        ))
                      }
                      {droppableProvided.placeholder}
                    </Box>
                  )
                }
              </Droppable>
            </DragDropContext>
          </>
        )}
      />
    </FormikProvider>
  );
};

export default FieldCollection;
