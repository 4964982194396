import { requestApi } from 'api/request-api';

const schoolFilterSearch = (search, setOptions, canBeDisabled = false) => {
  if (search.length) {
    requestApi.getResponse({ url: `web/schools/choices?&search=${encodeURIComponent(search)}` }).then((response) => {
      if (!Array.isArray(response)) {
        setOptions([]);
      }

      const choices = response.map((row) => ({
        id: row.value,
        label: `${row.name}, ${row.address.postcode}`,
        // add disabled true/false but only add if prop
        // has been passed so that it doesnt affect other areas,
        // will more than likely need updating if we need to use disabled
        // elsewhere.
        ...(canBeDisabled && { disabled: row.hasSchoolLearningGroup }),
      }));

      setOptions(choices);
    });
  } else {
    setOptions([]);
  }
};

export default schoolFilterSearch;
