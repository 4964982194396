import { Alert, Box, Typography } from '@mui/material';
import FormField from 'components/shared/FormField';
import { Form, Formik } from 'formik';
import { debounce } from 'lodash';
import { useMemo, Component } from 'react';
import schoolFilterSearch from 'utils/schoolFilterSearch';
import PropTypes from 'prop-types';

export const ConvertLearningGroupForm = ({ formRef, convertRequestErrored, schoolAleadyLearningGroup }) => {
  const debouncedSchoolFilterSearch = useMemo(
    () => debounce((search, setOptions) => schoolFilterSearch(search, setOptions, true), 280),
    []
  );

  const learningGroupFields = {
    name: 'school',
    label: 'School (required)',
    type: 'typeahead',
    search: debouncedSchoolFilterSearch,
    apiAttribute: 'school',
  };

  return (
    <Formik
      enableReinitialize
      initialValues={{
        school: '',
      }}
      validateOnChange={false}
      innerRef={formRef}
      onSubmit={() => {}}
    >
      {(formik) => (
        <Form formik={formik}>
          <Typography variant="h6" gutterBottom>
            Search/select a school
          </Typography>
          <Box paddingTop="5px">
            <FormField {...learningGroupFields} getApiError={() => {}} formik={formik} />
          </Box>
          {schoolAleadyLearningGroup && (
            <Alert severity="error" sx={{ mt: 2 }}>
              The selected school is already assigned to a learning group and cannot be converted.
            </Alert>
          )}
          {convertRequestErrored && (
            <Alert severity="error" sx={{ mt: 2 }}>
              There has been a problem, please try again.
            </Alert>
          )}
        </Form>
      )}
    </Formik>
  );
};

ConvertLearningGroupForm.propTypes = {
  formRef: PropTypes.oneOfType([PropTypes.func, PropTypes.shape({ current: PropTypes.instanceOf(Component) })])
    .isRequired,
  convertRequestErrored: PropTypes.bool.isRequired,
  schoolAleadyLearningGroup: PropTypes.bool.isRequired,
};

export default ConvertLearningGroupForm;
