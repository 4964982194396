import { useParams } from 'react-router-dom';
import * as Yup from 'yup';

// WRM
import AddEditResource from 'components/shared/AddEditResource';
import moment from "moment";
import {useEffect, useState} from "react";
import PropTypes from "prop-types";
import {useAppContext} from "contexts/app-context";
import {requestApi} from "api/request-api";
import {resourceApi} from "api/resource-api";
import {toApiValues, toFormValues} from "components/shared/resource/api-form-mapper";

const apiEndpoint = 'licences';
const resourceName = 'licence';

const buildFields = ({membershipPlanChoices}) => {

  const fields = [
    {
      name: 'ownedByName',
      label: 'Owner',
      type: 'text',
      disabled: true
    },
    {
      name: 'membershipPlan',
      label: 'Membership plan',
      type: 'select',
      choices: membershipPlanChoices
    },
    {
      name: 'startsAt',
      label: 'Starts',
      type: 'date',
      defaultValue: moment().utc().format('YYYY-MM-DDTHH:mm:ss+00:00'),
    },
    {
      name: 'expiresAt',
      label: 'Expires',
      type: 'date',
      defaultValue: moment().utc().add(1, 'Y').format('YYYY-MM-DDTHH:mm:ss+00:00'),
    },
    {
      name: 'maxMembers',
      label: 'Maximum number of members',
      type: 'number',
      defaultValue: 1,
    },
    {
      name: 'isActive',
      label: 'Active?',
      type: 'checkbox',
      defaultValue: true,
    },
  ];

  return fields;
}

const validationSchema = Yup.object().shape({
  maxMembers: Yup.number()
      .typeError('Invalid number')
      .positive('Maximum number of members must be positive')
      .required('Maximum number of members is required'),
  membershipPlan: Yup.string()
      .matches(/^\/membership/, 'Membership plan is required'),
  startsAt: Yup.date()
      .typeError('Invalid date format')
      .nullable(),
  expiresAt: Yup.date()
      .typeError('Invalid date format')
      .nullable()
      .min(Yup.ref('startsAt'), 'Expiry date must be later than start date'),
});

const AddEditLicence = ({ isModal, licenceId, onModalClose, learningGroup, isTeamMembershipPlan }) => {
  const params = useParams();
  let id;
  if (licenceId) {
    id = licenceId;
  } else if(learningGroup) {
    id = null;
  } else {
    id = params.id ? Number(params.id) : null;
  }

  const membershipPlanChoicesUrl = (teamChoices) => {
    if(teamChoices !== null) {
      return `membership-plans/choices?isTeamMembershipPlan=${teamChoices ? 'true' : 'false'}`;
    }
    return 'membership-plans/choices';
  }

  const [fields, setFields] = useState(null);
  const { setShowLoadingSpinner } = useAppContext();

  // eslint-disable-next-line no-shadow
  const toApiValuesCustom = (formValues, fields) => {
    // Perform standard mapping
    const apiValues = toApiValues(formValues, fields);

    if (id === null && learningGroup) {
      apiValues.ownedBy = learningGroup['@id'];
      apiValues.accessibleBy = learningGroup['@id'];
    }

    return apiValues;
  }

  // eslint-disable-next-line no-shadow
  const toFormValuesCustom = (resource, fields) => {
    const formValues = toFormValues(resource, fields);
    formValues.ownedByName = learningGroup ? learningGroup.name : resource.ownedBy.name;
    return formValues;
  }

  useEffect(async () => {
    setShowLoadingSpinner(true);

    let teamChoices = isTeamMembershipPlan;

    if(teamChoices === null && id !== null) {
      const licence = await resourceApi.getResource({
        apiEndpoint: 'licences',
        id
      });
      const ownedByLearningGroupType = licence?.ownedBy['@type'] ?? null;
      teamChoices = ownedByLearningGroupType !== 'IndividualLearningGroup';
    }

    const membershipPlanChoices = await requestApi.getResponse({
      url: membershipPlanChoicesUrl(teamChoices)
    });

    setFields(buildFields({membershipPlanChoices}));
    setShowLoadingSpinner(false);
  }, []);

  if(fields === null) {
    return '';
  }

  return (
    <AddEditResource
      apiEndpoint={apiEndpoint}
      fields={fields}
      id={id}
      isModal={isModal}
      onModalClose={onModalClose}
      resourceName={resourceName}
      toApiValuesCustom={toApiValuesCustom}
      toFormValuesCustom={toFormValuesCustom}
      validationSchema={validationSchema}
    />
  );
};

AddEditLicence.propTypes = {
  isModal: PropTypes.bool,
  licenceId: PropTypes.number,
  onModalClose: PropTypes.func,
  // eslint-disable-next-line react/forbid-prop-types
  learningGroup: PropTypes.object,
  isTeamMembershipPlan: PropTypes.bool,
};

AddEditLicence.defaultProps = {
  isModal: false,
  licenceId: null,
  onModalClose: null,
  learningGroup: null,
  isTeamMembershipPlan: null
}

export default AddEditLicence;
