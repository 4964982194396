/* eslint-disable */
import { getIn } from 'formik';

// MUI
import {
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select as MuiSelect,
} from '@mui/material';

const Select = (props) => {
  const {
    choices: formFieldChoices,
    formik,
    getApiError,
    getChoices = null,
    label,
    name,
    onChange,
  } = props;

  const choices = getChoices ? getChoices(formik.values, name) : formFieldChoices;

  return (
    <FormControl fullWidth>
      <InputLabel>{label}</InputLabel>
      <MuiSelect
        error={Boolean(getApiError(name) || (getIn(formik.touched, name) && getIn(formik.errors, name)))}
        name={name}
        multiple={Array.isArray(getIn(formik.values, name))}
        onBlur={formik.handleBlur}
        onChange={onChange || formik.handleChange}
        value={getIn(formik.values, name)}
      >
        {
          choices?.map((choice) => (
            <MenuItem
              key={choice.value}
              value={choice.value}
            >
              {choice.label}
            </MenuItem>
          ))
        }
      </MuiSelect>
      {
        (Boolean(getApiError(name) || (getIn(formik.touched, name) && getIn(formik.errors, name)))) && (
          <FormHelperText error>{getApiError(name) || (getIn(formik.touched, name) && getIn(formik.errors, name))}</FormHelperText>
        )
      }
    </FormControl>
  );
};

export default Select;
