/* eslint-disable */
// WRM
import ListResources from 'components/shared/ListResources';
import { schoolFullAddress } from 'utils/school-helpers';
import { useMemo } from 'react';
import { debounce } from 'lodash';
import schoolFilterSearch from 'utils/schoolFilterSearch';

const title = 'School Groups';
const apiEndpoint = 'school-learning-groups';
const resourceName = 'School Group';

const columns = [
  {
    field: 'name',
    label: 'Group Name',
    flex: 2,
    formatter: (resource) => resource.name,
  },
  {
    field: '_schoolAddress',
    label: 'School Address',
    sortable: true,
    flex: 4,
    formatter: (resource) => schoolFullAddress(resource.school),
  },
  {
    field: '_schoolUrn',
    label: 'URN',
    sortable: true,
    flex: 1,
    formatter: (resource) => resource.school?.urn ?? '-',
  },
  {
    field: '_userCount',
    label: '# Active Users',
    flex: 1,
    formatter: (resource) => `${resource.learningGroupAccesses.filter((access) => access.isActive).length}`,
  },
  {
    field: '_activeOwnedLicenceCount',
    label: '# Owned Licences',
    flex: 1,
    formatter: (resource) => `${resource.ownedLicences.length}`,
  },
];

const defaultFilterValues = {};

const fixedFilterValues = {};

const SchoolLearningGroups = () => {
  const debouncedSchoolFilterSearch = useMemo(
    () => debounce((search, setOptions) => schoolFilterSearch(search, setOptions), 280),
    []
  );

  const filters = [
    {
      field: 'name',
      label: 'Group Name',
      type: 'text',
    },
    {
      field: 'school',
      label: 'School',
      type: 'typeahead',
      search: debouncedSchoolFilterSearch,
      apiAttribute: 'school',
    },
  ];

  return (
    <ListResources
      apiEndpoint={apiEndpoint}
      columns={columns}
      defaultFilterValues={defaultFilterValues}
      filters={filters}
      fixedFilterValues={fixedFilterValues}
      resourceName={resourceName}
      title={title}
    />
  );
};

export default SchoolLearningGroups;
