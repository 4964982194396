/* eslint-disable */
// WRM
import { useEffect, useMemo, useState } from 'react';
import getDateUtcString from 'utils/get-date-utc-string';
import PropTypes from 'prop-types';
import { resourceApi } from 'api/resource-api';
import { Box, CircularProgress, Dialog, DialogContent } from '@mui/material';
import MDTypography from 'mdpr2/components/MDTypography';
import MDButton from 'mdpr2/components/MDButton';
import ListResourcesTable from 'components/shared/ListResources/ListResourcesTable';
import AddEditLicence from '../Licences/AddEditLicence';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import { asTitleCase } from 'utils/helpers';
import loadLicences from 'utils/load-licences';

const apiEndpoint = 'licences';

const columns = [
  {
    field: 'openInNewTab',
    label: 'Order/Renewal',
    flex: 0.1,
    formatter: (resource) => {
      const order = resource.renewedByOrder ?? resource.order ?? null;
      const url = order ? `/admin/orders/edit/${order.id}` : null;

      return (
        <MDButton
          disabled={order === null}
          color="info"
          title="Order"
          iconOnly
          className="open-in-new-tab"
          onClick={() => window.open(url)}
        >
          <OpenInNewIcon />
        </MDButton>
      );
    },
  },
  {
    field: 'membershipPlanName',
    label: 'Membership plan',
    flex: 3,
    formatter: (resource) => {
      const renewedByOrder = resource.renewedByOrder ?? null;

      return (
        <>
          <b>{resource.membershipPlan?.name}</b>

          {renewedByOrder ? (
            <>
              <br />
              <span>
                Renewed by order{' '}
                <a target="_blank" href={`/admin/orders/edit/${renewedByOrder.id}`}>
                  #1{renewedByOrder.id} <OpenInNewIcon />
                </a>
              </span>
            </>
          ) : null}
        </>
      );
    },
  },
  {
    field: 'startsAt',
    label: 'Starts',
    sortable: true,
    flex: 1,
    formatter: (resource) => getDateUtcString(resource.startsAt, 'date'),
  },
  {
    field: 'expiresAt',
    label: 'Expires',
    sortable: true,
    flex: 1,
    formatter: (resource) => getDateUtcString(resource.expiresAt, 'date'),
  },
  {
    field: 'status',
    label: 'Status',
    sortable: true,
    flex: 1,
    formatter: ({ status, isActive }) => {
      const pretty = isActive ? asTitleCase(status) : 'Not active';
      let color = 'red';
      if (status === 'current') {
        if (isActive) {
          color = 'green';
        } else {
          color = 'orange';
        }
      }

      return <b style={{ color }}>{pretty}</b>;
    },
  },
  {
    field: 'maxMembers',
    label: 'Max Members',
    flex: 1,
  },
];

const LearningGroupLicences = ({ learningGroup }) => {
  const [licences, setLicences] = useState(null);
  const [addLicenceModalOpen, setAddLicenceModalOpen] = useState(false);
  const isTeamMembershipPlan = learningGroup['@type'] !== 'IndividualLearningGroup';

  const addLicence = () => {
    setAddLicenceModalOpen(true);
  };

  useEffect(async () => {
    const licencesResponse = await loadLicences(learningGroup?.id);

    setLicences(licencesResponse);
  }, [learningGroup]);

  const onModalClose = async () => {
    setAddLicenceModalOpen(false);
    await loadLicences(learningGroup?.id, setLicences);
    // loadNotes ();
  };

  if (licences === null) {
    return (
      <Box marginTop={5} justifyContent="center">
        <CircularProgress /> Loading...
      </Box>
    );
  }

  return (
    <>
      <MDTypography variant="h6">{licences.length} Licence(s)</MDTypography>
      <Box sx={{ mt: -1, mb: 2 }} display="flex" alignItems="right" justifyContent="flex-end">
        <MDButton color="info" onClick={addLicence}>
          Add Licence
        </MDButton>
      </Box>
      <ListResourcesTable
        apiEndpoint={apiEndpoint}
        columns={columns}
        isAddEditModal
        onAddEditModalClose={onModalClose}
        resources={licences}
        resourceCount={licences.length}
      />
      <Dialog
        open={addLicenceModalOpen}
        onClose={onModalClose}
        maxWidth={false}
        fullWidth
        scroll="paper"
        aria-labelledby="add-licence"
        aria-describedby="add-licence"
      >
        <DialogContent>
          <AddEditLicence
            isModal
            onModalClose={onModalClose}
            learningGroup={learningGroup}
            isTeamMembershipPlan={isTeamMembershipPlan}
          />
        </DialogContent>
      </Dialog>
    </>
  );
};

LearningGroupLicences.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  learningGroup: PropTypes.object.isRequired,
};

export default LearningGroupLicences;
