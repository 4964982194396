/* eslint-disable */
// WRM
import ListResources from 'components/shared/ListResources';
import {schoolFullAddress} from "utils/school-helpers";
import {useMemo} from "react";
import {debounce} from "lodash";
import schoolFilterSearch from "utils/schoolFilterSearch";
import {learningGroupPrettyType} from "../../../utils/helpers";
import {editPath} from "utils/learning-group-helpers";
import {useNavigate} from "react-router-dom";

const title = 'All Groups';
const apiEndpoint = 'learning-groups';
const resourceName = 'Group';

const columns = [
    {
        field: 'type',
        label: 'Type',
        flex: 2,
        formatter: (resource) => learningGroupPrettyType(resource),
    },
    {
        field: 'name',
        label: 'Name',
        flex: 2,
        formatter: (resource) => resource.name,
    },
    {
        field: '_address',
        label: 'Address',
        sortable: true,
        flex: 4,
        formatter: (resource) => {

            if(resource['@type'] === 'SchoolLearningGroup') {
                return schoolFullAddress(resource.school);
            }

            return '-';
        },
    },
    {
        field: '_schoolUrn',
        label: 'URN',
        sortable: true,
        flex: 1,
        formatter: (resource) => resource.school?.urn ?? '-',
    },
    {
        field: '_userCount',
        label: '# Active Users',
        flex: 1,
        formatter: (resource) => `${resource.learningGroupAccesses.filter(access => access.isActive).length}`,
    },
    {
        field: '_activeOwnedLicenceCount',
        label: '# Owned Licences',
        flex: 1,
        formatter: (resource) => `${resource.ownedLicences.length}`,
    },

];

const defaultFilterValues = {};

const fixedFilterValues = {
    excludeIndividuals: 1
};

const LearningGroups = () => {

    const navigate = useNavigate();

    const onRowDoubleClick = (learningGroup) => {
        navigate(editPath(learningGroup));
    }

    const debouncedSchoolFilterSearch = useMemo(
        () => debounce((search, setOptions) => schoolFilterSearch(search, setOptions), 280),
        []
    );

    const filters = [
        {
            field: 'name',
            label: 'Name',
            type: 'text',
        },
        {
            field: 'school',
            label: 'School',
            type: 'typeahead',
            search: debouncedSchoolFilterSearch,
            apiAttribute: 'school',
        },
    ];

    return (
        <ListResources
            apiEndpoint={apiEndpoint}
            columns={columns}
            defaultFilterValues={defaultFilterValues}
            filters={filters}
            fixedFilterValues={fixedFilterValues}
            resourceName={resourceName}
            title={title}
            disableOpenInNewTab
            onRowDoubleClick={onRowDoubleClick}
            disableAddResource
        />
    );
};

export default LearningGroups;
