/* eslint-disable */
// WRM
import ListResources from 'components/shared/ListResources';
import {useMemo} from "react";
import {debounce} from "lodash";
import schoolFilterSearch from "utils/schoolFilterSearch";
import {learningGroupPrettyType} from "../../../utils/helpers";
import getDateUtcString from "../../../utils/get-date-utc-string";

const title = 'Licences';
const apiEndpoint = 'licences';
const resourceName = 'Licence';

const ownerDescription = (licence) => {
    const learningGroupType = learningGroupPrettyType(licence.ownedBy);
    return licence.ownedBy.name + ' (' + learningGroupType + ')'
};

const columns = [
    {
        field: '_owner',
        label: 'Owner',
        flex: 2,
        formatter: ownerDescription,
    },
    {
        field: 'membershipPlanName',
        label: 'Membership plan',
        flex: 3,
        formatter: (resource) => resource.membershipPlan?.name,
    },
    {
        field: 'startsAt',
        label: 'Starts',
        sortable: true,
        flex: 1,
        formatter: (resource) => getDateUtcString(resource.startsAt, 'date'),
    },
    {
        field: 'expiresAt',
        label: 'Expires',
        sortable: true,
        flex: 1,
        formatter: (resource) => getDateUtcString(resource.expiresAt, 'date'),
    },
    {
        field: 'maxMembers',
        label: 'Max Members',
        flex: 1
    },
];

const defaultFilterValues = {};

const fixedFilterValues = {};

const Licences = () => {

    const debouncedSchoolFilterSearch = useMemo(
        () => debounce((search, setOptions) => schoolFilterSearch(search, setOptions), 280),
        []
    );

    const filters = [
        // {
        //     field: 'ownerType',
        //     label: 'Owner Type',
        //     type: 'select',
        //     choices: [
        //         {
        //             value: 'IndividualLearningGroup',
        //             label: 'Individual'
        //         },
        //         {
        //             value: 'SchoolLearningGroup',
        //             label: 'School Group'
        //         },
        //         {
        //             value: 'CustomLearningGroup',
        //             label: 'Custom Group'
        //         },
        //     ]
        // },
        {
            field: 'ownerEmailName',
            label: 'Owner Name',
            type: 'text',
            apiAttribute: 'ownedBy.name'
        },
        // {
        //     field: 'school',
        //     label: 'School',
        //     type: 'typeahead',
        //     search: debouncedSchoolFilterSearch,
        //     apiAttribute: 'school',
        // },
    ];

    return (
        <ListResources
            apiEndpoint={apiEndpoint}
            columns={columns}
            defaultFilterValues={defaultFilterValues}
            filters={filters}
            fixedFilterValues={fixedFilterValues}
            resourceName={resourceName}
            title={title}
            disableAddResource
        />
    );
};

export default Licences;
